@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Original Surfer";
  src: url("./assets/fonts/OriginalSurfer-Regular.ttf");
}
@font-face {
  font-family: "Poppins-medium";
  src: url("./assets/fonts/Poppins-Medium.ttf");
}
@font-face {
  font-family: "Poppins-thin";
  src: url("./assets/fonts/Poppins-Thin.ttf");
}
@font-face {
  font-family: "Poppins-light";
  src: url("./assets/fonts/Poppins-Light.ttf");
}

input,
textarea,
input::placeholder {
  font-family: "Poppins-Light";
}

.custom-gradient {
  background: linear-gradient(to bottom, black 40%, transparent),
    linear-gradient(to top right, #005ddd, transparent),
    linear-gradient(to top left, #fd2870 50%, transparent);
}
