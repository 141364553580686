.btn-prime, .btn-accent{
    padding: 5px 20px;
    background: #001A2C;
    color: #dedede;
    border: none;
    height: 38px;
    border-radius: 7px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 18px;
    text-decoration: none;
    .icon{
        fill: #dedede;
        height: 24px;
        width: 24px;
    }

    &:hover{
        filter: brightness(110%);
    }

}
.btn-accent{
    background-color: #012D4E;
}